var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-menu d-flex flex-row align-center justify-end"},[_c('div',{staticClass:"items-wrapper"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},[_vm._l((_vm.items),function(item,idx){return [(
            _vm.isAuthenticated ||
              (!_vm.isAuthenticated &&
                _vm.$ebsn.meta(
                  item,
                  'templatenavitemnavigationtype.HIDE_NOT_LOGGED'
                ) !== true)
          )?_c('div',{key:idx,staticClass:"mx-2 mx-md-3 d-flex justify-center align-center link"},[(item.templateNavItems?.length === 0)?_c('a',{staticClass:"text-decoration-none",class:item.classes,attrs:{"aria-label":item.name,"title":item.name,"href":item.itemUrl},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-menu',{attrs:{"bottom":"","offset-y":"","x":"","content-class":"children-links-menu py-1"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"px-1 px-lg-3",class:item.classes,attrs:{"aria-label":item.name,"title":item.name,"id":`header-menu-${item.templateNavItemId}`},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},'a',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.name))]),(item.templateNavItems?.length > 0)?_c('v-icon',{attrs:{"color":"default","small":""}},[_vm._v(" $chevronDown ")]):_vm._e()],1)]}}],null,true)},[_c('div',_vm._l((item.templateNavItems),function(child,idx2){return _c('div',{key:idx2},[_c('a',{staticClass:"text-body-2 default--text d-block w-100 nav-link pa-2",class:child.classes,attrs:{"aria-label":child.name,"title":child.name,"href":child.itemUrl},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(child.name)+" ")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((child.templateNavItems),function(child2,idx3){return _c('a',{key:idx3,staticClass:"text-body-2 default--text my-1 d-block w-100 nav-link pa-2",class:child2.classes,attrs:{"aria-label":child2.name,"title":child2.name,"href":child2.itemUrl || '#',"target":child2.target || '_self'},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_c('span',{class:child2.classes},[_vm._v(_vm._s(child2.name))])])}),0)])}),0)])],1):_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }