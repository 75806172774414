<template>
  <div class="navbar-menu d-flex flex-row align-center justify-end">
    <div class="items-wrapper">
      <v-slide-group multiple show-arrows>
        <template v-for="(item, idx) in items">
          <div
            :key="idx"
            v-if="
              isAuthenticated ||
                (!isAuthenticated &&
                  $ebsn.meta(
                    item,
                    'templatenavitemnavigationtype.HIDE_NOT_LOGGED'
                  ) !== true)
            "
            class="mx-2 mx-md-3 d-flex justify-center align-center link"
          >
            <a
              :aria-label="item.name"
              :title="item.name"
              v-if="item.templateNavItems?.length === 0"
              class="text-decoration-none"
              :class="item.classes"
              :href="item.itemUrl"
              @click.prevent="clicked"
            >
              {{ item.name }}
            </a>

            <v-menu
              v-else
              bottom
              offset-y
              x
              content-class="children-links-menu py-1"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  :aria-label="item.name"
                  :title="item.name"
                  :id="`header-menu-${item.templateNavItemId}`"
                  v-bind="attrs"
                  v-on.stop="on"
                  class="px-1 px-lg-3"
                  :class="item.classes"
                  @click.prevent="clicked"
                >
                  <span class="mr-1">{{ item.name }}</span>
                  <v-icon
                    color="default"
                    v-if="item.templateNavItems?.length > 0"
                    small
                  >
                    $chevronDown
                  </v-icon>
                </a>
              </template>
              <div>
                <div v-for="(child, idx2) in item.templateNavItems" :key="idx2">
                  <a
                    :aria-label="child.name"
                    :title="child.name"
                    class="text-body-2 default--text d-block w-100 nav-link pa-2"
                    :class="child.classes"
                    :href="child.itemUrl"
                    @click.prevent="clicked"
                  >
                    {{ child.name }}
                  </a>
                  <div class="d-flex flex-column">
                    <a
                      :aria-label="child2.name"
                      :title="child2.name"
                      v-for="(child2, idx3) in child.templateNavItems"
                      :key="idx3"
                      :href="child2.itemUrl || '#'"
                      class="text-body-2 default--text my-1 d-block w-100 nav-link pa-2"
                      :class="child2.classes"
                      :target="child2.target || '_self'"
                      @click.prevent="clicked"
                    >
                      <span :class="child2.classes">{{ child2.name }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </template>
      </v-slide-group>
    </div>
  </div>
  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
</template>

<script>
import clickHandler from "~/mixins/clickHandler";

import { mapGetters } from "vuex";

export default {
  name: "LinksMenu",
  mixins: [clickHandler],
  props: {
    items: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  height: $navbar-menu-height;
  .drawer-button {
    height: $navbar-menu-height;
    min-width: $drawer-btn-width;
  }
  .items-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    height: 35px;
  }

  a {
    color: var(--v-default-base) !important;
    line-height: 2;
  }
}
.children-links-menu {
  background-color: white;
  margin-top: 5px !important;
  max-width: 100%;
  min-width: 150px !important;
  border-radius: 12px 12px 12px 0px !important;
  z-index: 10 !important;
  .v-menu__content {
    box-shadow: 0px 3px 6px #00000029;
  }
  a {
    text-decoration: none;
  }
  .v-card {
    &.yellow-gradient {
      background-color: #dda801;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(191, 145, 6, 1) 28%,
        rgba(205, 156, 4, 1) 39%,
        #dda801 44%
      );
    }
    &.transparent-gradient {
      .v-card__title,
      .v-card__subtitle {
        z-index: 2;
      }
      .gradient-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, black, transparent);
      }
    }
  }
  .nav-link:hover {
    background-color: var(--v-grey-lighten1);
  }
}
</style>
